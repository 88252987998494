import Vue from 'vue'
import Vuex from 'vuex'
import storage from "../utils/storage"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: storage.getItem('token', ''),
        userId: storage.getNumber('userId', 0),
        userType: storage.getNumber('userType', 0),
        userMode: storage.getNumber('userMode', 0), // 1-会员；2-员工
        chainId: 0, //连锁ID
        hotelId: 0, //酒店ID
    },
    mutations: {
        login(state, data) {
            state.token = data.token
            state.userId = data.userId
            state.userType = data.userType
            state.userMode = data.userMode
            storage.setItem('token', data.token)
            storage.setItem('refreshToken', data.refreshToken)
            storage.setItem('expireTime', data.expireTime)
            storage.setItem('userId', data.userId)
            storage.setItem('userType', data.userType)
            storage.setItem('userMode', data.userMode)
        },
        refreshToken(state, data) {
            state.token = data.token
            storage.setItem('token', data.token)
            storage.setItem('refreshToken', data.refreshToken)
            storage.setItem('expireTime', data.expireTime)
        },
        logout(state) {
            state.token = ''
            state.userId = 0
            state.userType = 0
            state.userMode = 0
            storage.removeItem('token')
            storage.removeItem('refreshToken')
            storage.removeItem('expireTime')
            storage.removeItem('userId')
            storage.removeItem('userType')
            storage.removeItem('userMode')
        }
    },
    actions: {},
    modules: {}
})
