<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import querystring from 'querystring'
import wxApi from "@/utils/wxApi";

export default {
    created() {
        let ua = navigator.userAgent.toLowerCase()
        if (ua.match(/miniprogram/i)) { //小程序环境
            console.info('小程序环境')
            let data = querystring.parse(window.location.search.substr(1))
            this.$store.commit('login', {
                // userId: data.userId,
                // userType: data.userType,
                userMode: 1,
                token: data.token,
                // expireTime: data.expire * 1000 + new Date().getTime()
            })
        } else if (ua.match(/MicroMessenger/i)) { //微信环境
            console.info('微信环境')
            if (!this.$store.state.token && window.location.href.indexOf('#/auth') < 0) {
                window.location.href = wxApi.getCode()
            } else if(this.$route.name === 'Index') {
                this.$router.replace('/my-rooms')
            }
        } else { //浏览器环境
            console.info('浏览器环境')
            // if (!this.$store.state.token) {
            //     this.$router.replace('/login')
            // } else if(this.$route.name === 'Index') {
            //     this.$router.replace('/hotel-list')
            // }
        }
    },
}
</script>