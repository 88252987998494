import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/hotel-list',
    name: 'HotelList',
    component: () => import(/* webpackChunkName: "hotelList" */ '../views/HotelList.vue')
  },
  {
    path: '/room-list',
    name: 'RoomList',
    component: () => import(/* webpackChunkName: "roomList" */ '../views/RoomList.vue')
  },
  {
    path: '/room-control',
    name: 'RoomControl',
    component: () => import(/* webpackChunkName: "roomControl" */ '../views/RoomControl.vue')
  },
  {
    path: '/my-rooms',
    name: 'MyRooms',
    component: () => import(/* webpackChunkName: "myRooms" */ '../views/MyRooms.vue')
  },
  {
    path: '/bind-mobile',
    name: 'BindMobile',
    component: () => import(/* webpackChunkName: "bindMobile" */ '../views/BindMobile.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
