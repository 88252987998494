<template>
    <div class="ui-page startup">
        <div class="hotel-btn">
            <van-button @click="linkHotelList">选择酒店</van-button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Index',
    methods: {
        linkHotelList() {
            this.$router.push('/hotel-list')
        }
    }
}
</script>

<style lang="css" scoped>
.startup {
    background: url(../assets/startup.jpg) no-repeat left center;
    background-size: cover;
}
.hotel-btn {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    text-align: center;
}
</style>